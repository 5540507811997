<template>
    <div id="establish">
        <div class="establishBox">
            <!--高德地图-->
            <div class="MapBox">
                <Map
                        :config="mapConfig"
                        ref="commonMap"
                />
            </div>
            <!--侧边栏-->
            <div class="leftBar" :style='style'>
                <div class="leftMain">
                    <projectNav
                            :ShowMarker="ShowMarker"
                    />
                </div>
                <div class="unfoldBth" @click="leftBarShou=!leftBarShou">
                    <i :class="leftBarShou?'ivu-icon ivu-icon-ios-arrow-back':'ivu-icon ivu-icon-ios-arrow-forward'" ></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Map from "@/components/statistics/commonComponents/map.vue";
    import projectNav from './projectNav.vue'

    export default {
        components: {
            Map,
            projectNav
        },
        data() {
            return {
                mapConfig: {
                    tools: [ "search", "measure","hierarchy", "zoomIn", "zoomOut"],
                    updateBySocket: false
                },
                style:{
                    left: '0'
                },
                leftBarShou:true
            };
        },
        watch:{
            leftBarShou(val){
                if(val){
                    this.style.left='0'
                }else {
                    this.style.left='-340px'
                }
            }
        },
        mounted() {


        },
        methods: {
            ShowMarker(listData, ProjectType){
                this.$refs.commonMap.projectNav_ShowMarker(listData, ProjectType);
            }
        },
    };
</script>

<style scoped lang="scss">
    #establish{
        width: 100%;
        height: 100%;
        .establishBox{
            width: 100vw;
            height: 100%;
            background-color: #fff;
            position: fixed;
            left: 0px;
            .MapBox{
                width: 100%;
                height: 100%;
            }
            .leftBar{
                width: 340px;
                height: 100%;
                /*border-top: 1px solid #f3f3f3;*/
                position: absolute;
                left: 0;
                top:0;
                transition: all 0.5s ease-in;
                .leftMain{
                    width:100%;
                    height: 100%;
                    background-color: #fafafa;
                }
                .unfoldBth{
                    position: absolute;
                    right: -26px;
                    top: 10px;
                    width: 26px;
                    height: 48px;
                    background-color: #fff;
                    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1);
                    border-radius: 0 6px 6px 0;
                    i{
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 26px;
                        line-height: 48px;
                    }
                }
            }
        }
        /deep/.amap-marker-label {
            //修改高德默认label样式
            background-color: #fff;
            text-align: center;
            width: 120px;
            height: 25px;
            border-radius: 4px;
            border: 1px solid #fff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
            overflow: hidden;
            padding: 0 4px;

            .mark_label {
                p {
                    line-height: 25px;
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
</style>
